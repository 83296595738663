import { graphql } from "gatsby";
import styled from "styled-components";
import { LineHeader, Title, Semi, CTA, Header } from "components/Module/Module";
import React from "react";
import { Link } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import EditorialGrid from "modules/EditorialGrid/EditorialGrid";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { toPlainText } from "../lib/helpers";
import { getBlogUrl } from "lib/helpers";
import { media } from "utils/mediaQuery";

export const query = graphql`
  query LandingPageTemplateQuery(
    $id: String!
  ) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: sanityLandingPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      editorial {
        mainImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        portraitImage {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        _rawDisplayTitle
        title
        publishedAt
        categories {
          title
        }
        slug {
          current
        }
      }
    }
  }
`;

const CustomLineHeader = styled(LineHeader)`
  margin: 0;


  ${media.large` 
    & > div {
      display: flex;
    } 
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LandingPageTemplate = (props) => {
  const { data, errors } = props;
  const site = (data || {}).site;
  const posts = data && data.posts;
  return (
    <Layout
    pageElements={
      <CustomLineHeader>
          <Title>{posts.title}</Title>
          <CTA>
            <Link to="/library">see all editorials</Link>
          </CTA>
      </CustomLineHeader>
    }
  >
    <SEO
      title={site.title}
      description={site.description}
      keywords={site.keywords}
    />
    {posts && (
      <>
        <EditorialGrid nodes={posts.editorial} gatsby/>
      </>
    )}
  </Layout>
  );
};

export default LandingPageTemplate;
